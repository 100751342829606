import preview from '@/assets/solution/ai-paraphraser/preview.png';
import infoItem1 from '@/assets/solution/ai-paraphraser/info-item-1.png';
import infoItem2 from '@/assets/solution/ai-paraphraser/info-item-2.png';
import infoItem3 from '@/assets/solution/ai-paraphraser/info-item-3.png';

export default {
  preview,
  toolName: 'paraphraser',
  solutionName: 'AI Paraphraser',
  videoUrl: 'https://www.youtube.com/embed/WidNDzNAB14',
  toolUrl: '/AI-paraphraser',
  infoList: [
    {
      title: 'Improve SEOs',
      content: 'Have you ever known rewriting content can potentially give you an SEO boost? By using our AI paraphraser to create multiple pieces of content from a single piece, you can link them together and improve your website\'s internal linking structure. When you have many content pieces targeting similar keywords, the search engines will generally mark you as an authority source, which can lead to higher search rankings.',
      imageUrl: infoItem1,
      contentOffset: 70,
    },
    {
      title: 'Diversify Social Media Content',
      content: 'As a marketer, you definitely have a moment feeling struck when it comes to creating fresh, unique posts on a regular schedule. No worries! With our AI paraphraser, you are capable of creating variations of existing content to be used across multiple platforms. It saves your time and increases your productivity by generating unlimited unique posts in no time.',
      imageUrl: infoItem2,
      contentOffset: 50,
    },
    {
      title: 'Repurposing Content',
      content: 'Repurposing content is a surefire way to increase your audience reach and maximize the potential of each piece you create. By producing one blog post, for example, you can craft an email newsletter or social media post - providing three different platforms with varied and separate audience groups. Now, with our AI paraphraser, you can effortlessly repurpose your content and turn your content into different formats simply in one click!',
      imageUrl: infoItem3,
      contentOffset: 80,
    }
  ],
  steps: [
    {
      name: 'Input',
      details: [
        'Write or paste the text in the left input box.',
      ]
    },
    {
      name: 'AI Generate',
      details: [
        'Click the \'AI Generate\' button and wait for seconds.'
      ],
    },
    {
      name: 'Outputs',
      details: [
        'Check for the 3 generated outputs and copy the text to use.',
      ],
    }
  ]
};
