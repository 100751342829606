import preview from '@/assets/home/eraser.mp4';
import infoItem1 from '@/assets/solution/object-eraser/info-item-1.png';
import infoItem2 from '@/assets/solution/object-eraser/info-item-2.png';
import infoItem3 from '@/assets/solution/object-eraser/info-item-3.png';

export default {
  preview,
  toolName: 'eraser',
  solutionName: 'Object Eraser',
  videoUrl: 'https://www.youtube.com/embed/TwyWwq1azCQ',
  toolUrl: '/object-eraser',
  infoList: [
    {
      title: 'Eliminate Disturbed and Unattractive Elements',
      content: 'Are you sick and tired of unexpected, unwanted objects stealing the show in your photographs? Whether it be an odd phrase on a wall or a logo on glassware - even that pesky cigarette butt spoiling an otherwise perfect shot. All these can add up to serious picture frustration! With Object Eraser, you can seamlessly erase any object, text or logo from your image with no trace left behind - giving you refreshed and perfect visuals as if nothing ever disturbed them.',
      resource: {
        url: infoItem1,
        isVideo: false,
        style: { marginBottom: '15px' },
      },
      contentOffset: 85,
    },
    {
      title: 'Wipe Away Wrinkles and Spots on Face',
      content: 'Are you feeling frustrated by pesky blemishes that ruin your selfies? Looking for a way to spruce up and perfect them without having to invest in expensive treatments or cosmetics? Get ready - You\'re one step away from taking beautiful, flawless photos! With this magical erase, you can wipe away wrinkles and spots for younger, smoother-looking skin. Erase years of aging with a single swipe!',
      imageUrl: infoItem2,
      contentOffset: 45,
    },
    {
      title: 'Enhance Product Visuals by Eliminating Distracting Elements',
      content: 'Erasing unwanted elements from your product shots can be a painless process. With the click of a button, unsightly picture frames, chairs and trash cans - as well as any other objects you don\'t want included in an image - are swiftly removed without ever having to return back for another take on it.',
      resource: {
        url: infoItem3,
        isVideo: false,
        style: { marginBottom: '10px' },
      },
      contentOffset: 25,
    }
  ],
  steps: [
    {
      name: 'Upload',
      details: [
        'Upload an image you want to remove an object from.',
      ]
    },
    {
      name: 'Erase',
      details: [
        'Use the brush tool to erase the object.',
        'Change the brush size to make it easier.',

      ],
    },
    {
      name: 'Download',
      details: [
        'Click \'Download\' to save your image.',
      ],
    }
  ]
};
