<template>
  <ul class='other-tool-list' ref="toolListRef" @click='handleClick'>
    <li
      v-for='(tool, index) in props.tools'
      :data-name='tool?.name'
      :key='index'
    >
      <template v-if="tool">
        <video
          loop
          muted
          autoplay
          class="tool-preview"
          :src="tool.preview"
          v-if="tool.preview.endsWith('.mp4')" 
        ></video>
        <img v-else class="tool-preview" :src='tool.preview'>
        <p class="tool-name">{{ tool.solutionName }}</p>
      </template>
    </li>
  </ul>
</template>

<script setup>
import { inject, ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

const toolListRef = ref(null);
const pageName = inject('pageName');
const router = useRouter();
const props = defineProps({
  tools: {
    type: Array,
    required: true,
  },
});

function handleClick(e) {
  const name = e.target.closest('li')?.dataset?.name;
  if (name !== undefined) {
    router.push('/solution/' + name);
    pageName.value = name;
  }
}

function handleElementResize() {
  const base = toolListRef.value.querySelector('li').clientWidth / 3.9;
  toolListRef.value.style.fontSize = `${Math.max(72, Math.min(100, base | 0))}px`;
}

const observer = new ResizeObserver(handleElementResize);
onMounted(() => {
  observer.observe(toolListRef.value);
});

onBeforeUnmount(() => {
  observer.disconnect();
});
</script>

<style scoped>
.other-tool-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: space-between;
  grid-gap: 56px .4em;
}

.tool-preview {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  clip-path: inset(1px);
}

.tool-preview:hover {
  box-shadow: 0 15px 40px #00000040;
}

.tool-name {
  margin-top: .8em;
  text-align: left;
  font-weight: 500;
  font-size: .2em;
}
</style>
