<template>
  <div class='solution'>
    <div class='landing-page'>
      <div
        class="landing-page-content"
        ref="contentRef"
        :style="{ transform: `scale(${scaleSize})` }"
      >
        <h3>{{ page.solutionName }}</h3>
        <button 
          class='try-button'
          @click='handleTryButton("booltool_solutions_tryit")'
        >
          Try it now
        </button>
        <div class='video-player-wrapper'>
          <video-player :style="{ aspectRatio: '800 / 450', maxWidth: '800px' }" :src='page.videoUrl' />
        </div>
      </div>
    </div>
    <div class="main-section">
      <div class="info-list-conatiner">
        <info-list :list="page.infoList"/>
      </div>
      <div>
        <div class='steps-container'>
          <p class='sub-title'>How to Use {{page.solutionName}}</p>
          <step-list :steps='page.steps' />
        </div>
        <button class="try-button" @click="handleTryButton('booltool_solutions_trytool')" style="--base-size: clamp(75px, 11.25vw, 100px)">
          Try {{ page.solutionName }}
        </button>
      </div>
      <div class="other-tool-list-conatiner">
        <p class="sub-title">Learn More With Booltool</p>
        <other-tool-list :tools='otherTools'/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide, watchEffect, onMounted } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import videoPlayer from './components/video-player.vue';
import infoList from './components/info-list.vue';
import stepList from './components/step-list.vue';
import otherToolList from './components/other-tool-list.vue';
import pagesConfig from './pagesConfig';
import { eventTracking } from '@/utils/eventTracking';

const pageName = ref(useRoute().params.name);
const otherTools = ref([]);
const router = useRouter();
const contentRef = ref(null);
const scaleSize = ref(1);
const page = ref({});

watchEffect(() => {
  if (!(pageName.value in pagesConfig)) {
    router.push('/');
  } else {
    page.value = pagesConfig[pageName.value];
    otherTools.value = Object.keys(pagesConfig)
      .filter(name => name !== pageName.value)
      .map(name => ({
        name: name,
        solutionName: pagesConfig[name].solutionName,
        preview: pagesConfig[name].preview,
      }));
  }
});

onBeforeRouteUpdate((to) => {
  pageName.value = to.params.name;
});

function handleTryButton(type) {
  eventTracking(type, { tool_name: page.value.toolName });
  window.open(page.value.toolUrl, '_blank');
}

onMounted(() => {
  eventTracking('booltool_solutions_page_view', { tool_name: page.value.toolName });
});

provide('pageName', pageName);
</script>

<style scoped>
.solution {
  height: 100%;
  font-family: 'Inter', Arial;
}

.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  background-color: black;
}

.landing-page-content {
  width: 90%;
  max-width: 800px;

  --base-size: min(11.25vw, 100px);
}

.landing-page-content > h3 {
  width: fit-content;
  margin: 30px auto;
  font-weight: 600;
  font-size: max(24px, calc(var(--base-size) * .48));
  vertical-align: top;
  color: transparent;
  line-height: 1.4;
  background: linear-gradient(270deg, #00FF57 0%, #00FFF0 100%);
  background-clip: text;
  -webkit-background-clip: text;
}

.try-button {
  position: relative;
  min-width: 10em;
  padding: .8em 2em;
  font-size: max(12px, calc(var(--base-size) * .18));
  color: white;
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
  border-radius: 800px;
  overflow: hidden;
}

.landing-page-content > .try-button {
  margin-bottom: 1.667em;
}

.try-button::after {
  display: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .1);
}

.try-button:hover::after {
  display: block;
}

.video-player-wrapper {
  margin-bottom: 30px;
}

.video-player-wrapper::after {
  content: '';
  display: block;
  margin: 6px auto 0;
  background-image: linear-gradient(0, rgba(255, 255, 255, 0), rgba(255, 255, 255, .15));
  aspect-ratio: 8 / 1;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 116px 53px;
  background-color: #F6F6F6;
  border-radius: 8px;
  margin-bottom: 54px;
}

.sub-title {
  font-weight: 600;
  font-size: clamp(24px, 3.27vw, 36px);;
  line-height: 1.6;
}

.info-list-conatiner, .steps-container {
  @apply w-lv;
}

.other-tool-list-conatiner {
  @apply w-lv;

  margin: 218px 0 200px
}

.other-tool-list-conatiner > .sub-title {
  margin-bottom: 2.3em;
}

@media screen and (orientation: landscape) {
  .landing-page {
    min-height: 100vh;
  }
}

@screen m {
  .info-list-conatiner, .steps-container, .other-tool-list-conatiner {
    @apply w-mv;
  }

  .steps-container {
    padding: 84px 44px;
  }

  .other-tool-list-conatiner {
    margin: 160px 0 140px
  }
}

@screen s {
  .info-list-conatiner, .steps-container > .sub-title, .steps-container > .step-list, .other-tool-list-conatiner {
    @apply w-sv;
  }

  .steps-container {
    width: 100%;
    padding: 84px 0;
  }

  .other-tool-list-conatiner > .sub-title {
    margin-bottom: 1.8em;
  }

  .other-tool-list-conatiner {
    margin: 112px 0 76px
  }
}
</style>