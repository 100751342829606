<template>
  <ul class='info-list' ref="infoListRef">
    <li v-for="info in list">
      <template v-if="info.resource">
        <advanced-video
          autoplay loop
          v-if="info.resource.isVideo"
          class="info-video"
          :style="info.resource.style"
          :src="info.resource.url"
        ></advanced-video>
        <img v-else class='info-image' :src='info.resource.url' :style="info.resource.style">
      </template>
      <img v-else class='info-image' :src='info.imageUrl'>
      <div
        :style='{ paddingTop: `.${ info.contentOffset }em` }'
        class='content-container'
      >
        <h4>{{ info.title }}</h4>
        <p>{{ info.content }}</p>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const infoListRef = ref(null); 
const props = defineProps({
  list: {
    type: Array,
    required: true,
  }
});

function handleElementResize() {
  const base = infoListRef.value.querySelector(':scope > li > .content-container').clientWidth / 5.66;
  infoListRef.value.style.fontSize = `${Math.max(12, Math.min(100, base | 0))}px`;
}

const observer = new ResizeObserver(handleElementResize);
onMounted(() => {
  observer.observe(infoListRef.value);
});

onBeforeUnmount(() => {
  observer.disconnect();
});
</script>

<style scoped lang="less">
.info-list {
  margin-top: 1.2em;

  & > li {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.3em;

    &:nth-child(odd) {
      & > .info-image, & > .info-video {
        transform: translateX(-7.6%);
      }
    }

    &:nth-child(even) {
      .content-container {
        order: -1;
        flex: 1;
      }

      & > .info-image, & > .info-video {
        transform: translateX(9.6%);
      }
    }
  }
}

.info-image, .info-video {
  width: 54.72%;
  object-fit: contain;
  object-position: 0 0;
}

.info-video {
  clip-path: inset(1px);
}

.info-image, .info-video, .content-container {
  display: inline-block;
}

.content-container {
  text-align: left;
}

.content-container > h4 {
  font-size: .36em;
  font-weight: 600;
  line-height: 1.32;
}

.content-container > p {
  margin-top: 1.5em;
  font-size: clamp(16px, .2em, 18px);
  line-height: 1.6;
}

@screen s {
  .content-container {
    padding-top: 0 !important;
  }

  .info-image, .info-video {
    width: 100%;
    transform: none !important;
  }

  .info-list > li {
    flex-direction: column;
  }

  .info-list > li:nth-child(even) > .content-container {
    order: 0;
  }
}
</style>