import preview from '@/assets/home/enhancer.webp';
import infoItem1 from '@/assets/solution/image-enhancer/info-item-1.png';
import infoItem2 from '@/assets/solution/image-enhancer/info-item-2.png';
import infoItem3 from '@/assets/solution/image-enhancer/info-item-3.png';

export default {
  preview,
  toolName: 'enhancer',
  solutionName: 'Image Enhancer',
  videoUrl: 'https://www.youtube.com/embed/D7w2kOlpARk',
  toolUrl: '/image-enhancer',
  infoList: [
    {
      title: 'Enhance Portrait Images',
      content: 'Image enhancer is a powerful tool to enhance the quality of your portrait photographs. Simply upload your images, and in seconds, you can enhance the visual appeal of your portraits with ease. Our enhancer works wonders by improving color balance, sharpness, and clarity, thus making the subject stand out and appear more polished. Whether you\'re looking to spruce up your social media profiles, create a stunning family album, or showcase your models\' images on your online shop, our enhancer has got you covered.',
      imageUrl: infoItem1,
      contentOffset: 65,
    },
    {
      title: 'Optimize User-Generated Content for Your Online Shop',
      content: 'User-generated images can be a powerful tool for building brand awareness, engagement, and loyalty in social media marketing. While these images may not always meet the desired quality standards for marketing purposes, especially when it comes to size and resolution. By using Image Enhancer, marketers can improve the visual quality of these images, making them more visually appealing and effective for marketing purposes.',
      imageUrl: infoItem2,
      contentOffset: 30,
    },
    {
      title: 'Meeting Your Printing Demands',
      content: 'Using sophisticated algorithms and artificial intelligence, image enhancer can help upscale low-resolution images to make them look more professional and polished-ideal for printing demands. Say goodbye to pixelated or blurry images. Upscale your small images to increase their resolution and size without losing quality and print them as a large poster or banner. Particularly useful for printing high-quality graphics or images, such as logos, infographics, or promotional materials.',
      resource: {
        url: infoItem3,
        isVideo: false,
        style: { marginBottom: '15px' },
      },
      contentOffset: 70,
    }
  ],
  steps: [
    {
      name: 'Upload',
      details: [
        'Upload images you want to enhance.',
      ]
    },
    {
      name: 'Enhance',
      details: [
        'Our Image Enhancer will automatically enhance your images.'
      ],
    },
    {
      name: 'Download',
      details: [
        'Click \'Download\' to save your images.',
      ],
    }
  ]
};
