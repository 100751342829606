import preview from '@/assets/home/videoEnhancer.mp4';
import infoItem1 from '@/assets/solution/video-enhancer/info-item-1.mp4';
import infoItem2 from '@/assets/solution/video-enhancer/info-item-2.mp4';
import infoItem3 from '@/assets/solution/video-enhancer/info-item-3.mp4';

export default {
  preview,
  toolName: 'video_enhancer',
  solutionName: 'Video Enhancer',
  videoUrl: 'https://www.youtube.com/embed/q04Sk69Zgys',
  toolUrl: '/video-enhancer',
  infoList: [
    {
      title: 'Enhance Product Video',
      content: 'Product videos are a popular marketing tool for e-commerce businesses, as they allow customers to see the product in action and gain a better understanding of its features and benefits. Video enhancer can improve the sharpness and clarity, and enhance the overall visual appeal of the product videos, making them more engaging and attractive to your customers.',
      resource: {
        url: infoItem1,
        isVideo: true,
      },
      contentOffset: 70,
    },
    {
      title: 'Optimize Your Video Story',
      content: 'Poor UGC videos can impede your audience engagement. With our video enhancer, you can easily turn your video into high resolution content in one click. Make your video story more visually appealing to audience and obtain more likes on social media like Tiktok, Instagram, Facebook, Twitter, etc.',
      resource: {
        url: infoItem2,
        isVideo: true,
        style: { marginBottom: '60px' }
      },
      contentOffset: 75,
    },
    {
      title: 'Repair Old Video',
      content: 'We all have precious life memories recorded in the age-old videos. These videos can suffer from various issues such as color fading, poor quality, and blurred images. By using our video enhancer tool, you can easily restore old videos and preserve important memories.',
      resource: {
        url: infoItem3,
        isVideo: true,
      },
      contentOffset: 18,
    }
  ],
  steps: [
    {
      name: 'Upload',
      details: [
        'Upload a video you want to enhance.',
      ]
    },
    {
      name: 'Preview',
      details: [
        'Wait for the preview of the enhanced video.'
      ],
    },
    {
      name: 'Download HD',
      details: [
        'Click \'Download HD\' button to download the full enhanced video.',
      ],
    }
  ]
};
