import preview from '@/assets/solution/background-remover/preview.png';
import infoItem1 from '@/assets/solution/background-remover/info-item-1.png';
import infoItem2 from '@/assets/solution/background-remover/info-item-2.png';
import infoItem3 from '@/assets/solution/background-remover/info-item-3.png';

export default {
  preview,
  toolName: 'bg_remover',
  solutionName: 'Background Remover & Changer',
  videoUrl: 'https://www.youtube.com/embed/RoBVyxzYvnY',
  toolUrl: '/object-background-remover',
  infoList: [
    {
      title: 'Elevate Your Profile Picture with a Cool, Stylish Background',
      content: 'Everyone can remove the background of portrait images in just 5 seconds. Immerse yourself in paradise by setting an oceanfront backdrop or summit the highest peaks. Make a stunning statement with celebrity-level poses, stylized effects, and much more—share it online, and print it out as a keepsake. Bring your creative vision to life with Booltool! This makes you stand out on social media and other digital platforms.',
      resource: {
        url: infoItem1,
        isVideo: false,
        style: { marginBottom: '20px' },
      },
      contentOffset: 75,
    },
    {
      title: 'Remove Background 10X Faster for Your Designs',
      content: 'Background removal is a common issue for professional designers, yet the task of manually removing one can be tedious and time-consuming. How long does it take? A minute here, five minutes there - or even a full twenty? Now you can take back hours of your repetitive workflow with one click to remove backgrounds from images. Save your time to design stunning visuals. Take the hassle out of background removal with Booltool – your automated assistant for all pixel editing.',
      resource: {
        url: infoItem2,
        isVideo: false,
        style: { marginBottom: '25px' },
      },
      contentOffset: 35,
    },
    {
      title: 'Increase Conversion Rates for Your Online Store',
      content: 'Product photography can present unique obstacles. In the outdoors, even inside the studio, cluttered imperfections or backgrounds are hard to avoid. Plain color background is never completely pure. Take your product photography to the next level with our AI custom-crafted backgrounds. They can help your business shine, and draw customers’ focus to the products being showcased, facilitating comparisons and encouraging trust--all while optimizing image-capturing efficiency. If you just wanna show your products, just try them.',
      imageUrl: infoItem3,
      contentOffset: 35,
    }
  ],
  steps: [
    {
      name: 'Upload',
      details: [
        'Upload images you want to remove background from.'
      ]
    },
    {
      name: 'Edit',
      details: [
        'Click \'Edit\' to erase & restore the results, then click \'Download\' to save the fine-tuned results.'
      ],
    },
    {
      name: 'Add Background',
      details: [
        'Upload a background or click \'Al Generate\' to add a fancy background.',
        'Move & scale the subjects of the images to fine-tune the results.',
        'Click \'Download\' to save the images.'
      ],
    }
  ]
};
