import backgroundRemoverConfig from './background-remover.config';
import imageEnhancerConfig from './image-enhancer.config';
import videoEnhancerConfig from './video-enhancer.config';
import backgroundBlurConfig from './background-blur.config';
import objectEraserConfig from './object-eraser.config';
import aiParaphraserConfig from './ai-paraphraser.config';
import photoStorytellingConfig from './photo-storytelling.config';

export default {
  'background-remover': backgroundRemoverConfig,
  'object-eraser': objectEraserConfig,
  'image-enhancer': imageEnhancerConfig,
  'background-blur': backgroundBlurConfig,
  'video-enhancer': videoEnhancerConfig,
  'AI-paraphraser': aiParaphraserConfig,
  // 'photo-storytelling': photoStorytellingConfig,
};
